<template>
  <div class="box product-detail">
    <div v-show="step === 1">
      <a-form :form="form" layout="inline">
        <div class="table-style-product">
          <div class="title">
            <span>{{
              id === "0" ? "商品基础信息录入" : "商品基础信息编辑"
            }}</span>
          </div>
          <div class="form-cont">
            <a-row>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-form-item label="商品名称" class="form-item-label items">
                  <a-input class="unityWidth" style="width: 280px" placeholder="商品名称长度不大于128个汉字" v-decorator="[
                    'name',
                    {
                      rules: [{ required: true, message: '请输入商品名称' }],
                      initialValue: product.name
                    }
                  ]"></a-input>
                </a-form-item>
              </a-col>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-form-item label="商品类型" class="form-item-label items" :label-col="{ span: 5 }"
                  :wrapper-col="{ span: 12 }">
                  <a-cascader style="width: 280px" changeOnSelect :options="options" @change="onChange"
                    placeholder="请输入商品类型" v-decorator="[
                      'categoryId',
                      {
                        rules: [{ required: true, message: '请输入商品类型' }],
                        initialValue: categoryIdList
                      }
                    ]" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-form-item label="买方类型">
                  <a-select mode="multiple" placeholder="请选择买方类型" style="width: 280px" v-decorator="[
                    'shoperType',
                    {
                      rules: [{ required: true, message: '请选择买方类型' }],
                      initialValue: product.shoperType
                    }
                  ]" @change="handleChange" @popupScroll="popupScroll">
                    <!-- <a-select-option v-for="i in shoperTypeList" :key="i">{{i}}</a-select-option> -->
                    <a-select-option value="TEACHER">老师</a-select-option>
                    <a-select-option value="STUDENT">学生</a-select-option>
                    <a-select-option value="OTHER">外部</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-form-item label="年龄层次">
                  <a-select mode="multiple" placeholder="请选择年龄层次" style="width: 280px" v-decorator="[
                    'ageStage',
                    {
                      rules: [{ required: true, message: '请选择年龄层次' }],
                      initialValue: product.ageStage
                    }
                  ]" @change="handleChange" @popupScroll="popupScroll">
                    <a-select-option value="KG">幼儿园</a-select-option>
                    <a-select-option value="PS">小学</a-select-option>
                    <a-select-option value="JS">初中</a-select-option>
                    <a-select-option value="HS">高中</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-form-item label="供货给">
                  <a-select id="mySelect" mode="multiple" placeholder="请选择学校" style="width: 280px" v-decorator="[
                    'blocTagBlocId',
                    {
                      rules: [{ required: true, message: '请选择供货方' }],
                      initialValue: product.blocTagBlocId
                    }
                  ]" @change="schoolSelect" @popupScroll="popupScroll">
                    <!-- v-decorator="['schoolBuySchoolId',{rules: [{ required: true, message: '请选择买方类型' }], initialValue: product.schoolBuySchoolId}]" <a-select-option v-for="i in shoperTypeList" :key="i">{{i}}</a-select-option> -->
                    <!-- <a-select-option value="true">全选</a-select-option> -->
                    <a-select-option v-for="item in schoolList" :key="item.id" :value="item.id">供货{{ item.name
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <a-form-item label="商品图片" class="items" required>
                  <!-- <div class="clearfix" style="width: 830px;">
                    <upload-pic class="upload-pic" v-model="pic" :multipleNmber="5" :size="1"></upload-pic>
                    <div class="upload-pic-info">
                      <a-icon type="info-circle" class="colorYellow" />支持jpg、gif、png格式上传，建议使用尺寸800*800像素以上，大小不超过1M的正方形图片(限五张)
                    </div>
                  </div>-->
                  <div class="clearfix pic-box">
                    <a-upload :multiple="true" :action="actions" listType="picture-card" :fileList="fileList"
                      @preview="handlePreview" @change="picChange">
                      <div v-if="fileList.length < 5">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传图片</div>
                      </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                      <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <a-form-item label="商品视频" class="items">
                  <div class="clearfix pic-box">
                    <a-upload :multiple="false" :fileList="fileList2" :before-upload="beforeUpload" :action="actions"
                      @change="videoChange">
                      <a-button> <a-icon type="upload" /> 上传视频 </a-button>
                      <p>注:视频大小限制为50M</p>
                    </a-upload>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <a-form-item label="商品详情" class="items" required>
                  <tinymce-editor v-if="id === '0' || (id !== '0' && product.name)" v-model="content"></tinymce-editor>
                  <!-- <tinymce-editor v-model="content"></tinymce-editor> -->
                </a-form-item>
              </a-col>
            </a-row>
            <!-- <a-row>
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <a-form-item label="规格数据" class="items" required>
                  <tinymce-editor-single v-model="propertyHtml"></tinymce-editor-single>
                </a-form-item>
              </a-col>
            </a-row>-->
            <a-row>
              <a-form-item label="规格数据" class="items spaces-box" required>
                <div style="display: flex;">
                  <div style="border: 1px solid #e8e8e8;padding: 0 5px;border-radius: 4px;">
                    <div style="text-align: center;line-height: 33px;border-bottom: 1px solid #e8e8e8;"
                      class="specifications">
                      规格
                    </div>
                    <a-input v-model="guige"
                      style="position: relative; top: 50%; transform: translate(0%, -112%);"></a-input>
                  </div>
                  <a-table class="plan" :columns="columns" :dataSource="spacesData" :pagination="false" rowKey="id"
                    size="small">
                    <template slot="value" slot-scope="text, record">
                      <a-input key="value" style="margin: -5px 0" :value="text" placeholder="套餐名称" @change="e => spacesHandle(e.target.value, record.id, 'value')
                        " />
                    </template>
                    <template slot="picDef" slot-scope="text, record" class="picDef-fileList-class">
                      <a-upload :action="actions" listType="picture-card" v-if="spacesData[record.id - 1]"
                        :fileList="spacesData[record.id - 1].picDefFileList" @preview="handlePreview"
                        @change="e => picDefChange(e, record.id)" class="bundle-pic">
                        <div v-if="spacesData[record.id - 1].picDefFileList.length < 1
                          ">
                          <a-icon type="plus" />
                          <div class="ant-upload-text">上传图片</div>
                        </div>
                      </a-upload>
                      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                        <img alt="example" style="width: 100%" :src="previewImage" />
                      </a-modal>
                    </template>
                    <template slot="unitPriceCost" slot-scope="text, record">
                      <a-input key="unitPriceCost" style="margin: -5px 0" :value="text" placeholder="协议价" @change="e =>
                        spacesHandle(
                          e.target.value,
                          record.id,
                          'unitPriceCost'
                        )
                        " />
                    </template>
                    <template slot="unitPrice" slot-scope="text, record">
                      <a-input key="unitPrice" style="margin: -5px 0" :value="text" placeholder="实际售价" @change="e =>
                        spacesHandle(e.target.value, record.id, 'unitPrice')
                        " />
                    </template>
                    <template slot="unitPriceShow" slot-scope="text, record">
                      <a-input key="unitPriceShow" style="margin: -5px 0" :value="text" placeholder="原价" @change="e =>
                        spacesHandle(
                          e.target.value,
                          record.id,
                          'unitPriceShow'
                        )
                        " />
                    </template>
                    <template slot="transportPrice" slot-scope="text, record">
                      <a-input key="transportPrice" style="margin: -5px 0" :value="text" placeholder="默认12元" @change="e =>
                        spacesHandle(
                          e.target.value,
                          record.id,
                          'transportPrice'
                        )
                        " />
                    </template>
                    <template slot="num" slot-scope="text, record">
                      <a-input key="num" style="margin: -5px 0" :value="text" placeholder="库存量" @change="e => spacesHandle(e.target.value, record.id, 'num')
                        " />
                    </template>
                    <template slot="operation" slot-scope="text, record">
                      <span>
                        <a @click="remove(record.id)">删除</a>
                      </span>
                    </template>
                  </a-table>
                </div>
                <a-button class="mb10" size="small" type="primary" @click="coreAdd">
                  <a-icon type="plus" />添加套餐类型
                </a-button>
              </a-form-item>
            </a-row>
          </div>
        </div>
        <div class="footer-btn-bottom">
          <a-button type="primary" @click="next" class="mr10">确认并保存</a-button>
          <!-- <a-button>返回</a-button> -->
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
// import UploadPic from '@/components/upLoader/UploadPic'
import TinymceEditor from "@/components/Editor/TinymceEditor";
// import TinymceEditorSingle from '@/components/Editor/TinymceEditorSingle'
import { addProduct, getProductDetail, modifyProduct } from "@/serve/product";
import { getBlocs } from "@/serve/open";
import global from "global";
import { getCategory, getCategoryDetail } from "@/serve/category";
export default {
  components: {
    // UploadPic
    TinymceEditor
    // TinymceEditorSingle
  },
  data() {
    return {
      form: this.$form.createForm(this),
      id: this.$route.params.id || "0",
      step: 1,
      index: -1,
      product: {},
      specPics: [],
      wrapperCol: {
        sm: { span: 20 },
        md: { span: 20 },
        lg: { span: 20 },
        xl: { span: 20 }
      },
      options: [],
      columns: [
        {
          title: "套餐名称",
          dataIndex: "value",
          key: "value",
          scopedSlots: { customRender: "value" }
        },
        {
          title: "套餐图片",
          dataIndex: "picDef",
          scopedSlots: { customRender: "picDef" }
        },
        {
          title: "供应商协议价",
          dataIndex: "unitPriceCost",
          key: "unitPriceCost",
          scopedSlots: { customRender: "unitPriceCost" }
        },
        {
          title: "实际售价",
          dataIndex: "unitPrice",
          key: "unitPrice",
          scopedSlots: { customRender: "unitPrice" }
        },
        {
          title: "原价",
          dataIndex: "unitPriceShow",
          key: "unitPriceShow",
          scopedSlots: { customRender: "unitPriceShow" }
        },
        {
          title: "运费",
          dataIndex: "transportPrice",
          key: "transportPrice",
          scopedSlots: { customRender: "transportPrice" }
        },
        {
          title: "库存",
          dataIndex: "num",
          key: "num",
          scopedSlots: { customRender: "num" }
        },
        {
          title: "操作",
          key: "action",
          width: "50px",
          scopedSlots: { customRender: "operation" }
        }
      ],
      spacesData: [
        {
          id: 1,
          value: "",
          picDefFileList: [],
          unitPriceCost: "",
          unitPrice: "",
          unitPriceShow: "",
          num: "",
          transportPrice: "",
          sku: ""
        }
      ],
      lastFreight: 0,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      fileList2: [],
      path: "",
      content: "",
      propertyHtml: "",
      guige: "",
      categroyStr: "", // 商品分类
      categoryIdList: [], // 商品分类
      schoolList: [],
      schoolSelected: []
      // pic: ''
    };
  },
  computed: {
    actions() {
      return global.file.url + "/sys/fs";
    }
  },
  methods: {
    coreAdd() {
      if (
        this.spacesData.length === 0 ||
        this.spacesData[this.spacesData.length - 1].value !== ""
      ) {
        const length = this.spacesData.length;
        this.spacesData.push({
          id: length === 0 ? 0 : this.spacesData[length - 1].id + 1,
          value: "",
          picDefFileList: [],
          unitPriceCost: "",
          unitPrice: "",
          unitPriceShow: "",
          num: "",
          sku: "",
          transportPrice: this.spacesData[length - 1].transportPrice
        });
      }
    },
    spacesHandle(value, id, column) {
      const newData = [...this.spacesData];
      const target = newData.filter(item => id === item.id)[0];
      if (target) {
        target[column] = value;
        this.spacesData = newData;
      }
    },
    remove(id, type) {
      let index = this.spacesData.findIndex(item => item.id === id);
      // const newData = this.spacesData.filter(item => item.id !== id)
      // this.spacesData = newData
      this.spacesData.splice(index, 1);
      for (let i = 0; i < this.spacesData.length; i++) {
        let item = this.spacesData[i];
        if (item.id > id) {
          item.id--;
        }
      }
      if (this.spacesData.length === 0) {
        this.spacesData.push({
          id: 1,
          value: "",
          picDefFileList: [],
          unitPriceCost: "",
          unitPrice: "",
          unitPriceShow: "",
          num: "",
          transportPrice: "",
          sku: ""
        });
      }
    },
    // 获取学校列表
    async getMySchool() {
      let list = await getBlocs();
      list.push({ id: 'school', name: '中小学' });//添加假选项中小学
      this.schoolList = list;
      // console.log(document.getElementById('mySelect'))
      // this.schoolList.map(item => {
      //   let select = ` <a-select-option value="${item.name}">${item.name}</a-select-option>`
      //   document.getElementById('mySelect').push(select)
      // })
    },
    // 获取照片
    getPhoto(id) {
      if (!id) {
        return false;
      } else if (id.indexOf(",") > -1) {
        let reg = new RegExp(",");
        let ids = id.replace(reg, "");
        return global.file.url + "/sys/fs/" + ids;
      } else {
        return global.file.url + "/sys/fs/" + id;
      }
    },
    // 商品分类
    onChange(val) {
      this.categroyStr = val[val.length - 1];
    },
    handleChange(value) {
      console.log(`Selected: ${value}`);
    },
    // 选择学校
    schoolSelect(val) {
      this.schoolSelected = val;
    },
    popupScroll() {
      console.log("popupScroll");
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },
    picChange({ fileList }) {
      console.log("图片", fileList);
      this.fileList = fileList;
    },
    picDefChange({ file, fileList }, id) {
      if (!fileList.length) {
        this.spacesData[id - 1].picDefFileList = [];
      } else {
        this.specPics = fileList;
        this.spacesData[id - 1].picDefFileList = this.specPics;
        this.specPics = [];
      }
    },
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 50) {
        this.$message.error("视频文件不能大于50M, 请重新上传！");
        return false;
      }
    },
    videoChange({ fileList }) {
      this.fileList2 = fileList;
    },
    // 下一步
    next(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.spaces = [];
          if (!this.fileList.length) {
            return this.$message.error("请上传商品图片");
          }
          if (this.fileList2.length >= 1) {
            values.video = this.fileList2[0].response.id;
          }
          if (!this.content) {
            return this.$message.error("请输入商品详情");
          }
          if (!this.guige) {
            return this.$message.error("请输入规格");
          }
          for (let i = 0; i < this.spacesData.length; i++) {
            let item = this.spacesData[i];
            if (!item.value) {
              return this.$message.error("请输入套餐名称");
            }
            if (!item.picDefFileList.length) {
              return this.$message.error("请上传套餐图片");
            }
            if (!item.unitPriceCost) {
              return this.$message.error("请输入协议价");
            }
            if (!item.unitPrice) {
              return this.$message.error("请输入实际售价");
            }
            if (!item.num) {
              return this.$message.error("请输入库存");
            }
            values.spaces.push({
              transportPrice: parseFloat(item.transportPrice),
              num: item.num,
              sku: item.sku,
              unitPriceCost: item.unitPriceCost,
              unitPrice: item.unitPrice,
              picDef: item.picDefFileList[0].response
                ? item.picDefFileList[0].response.id
                : item.picDefFileList[0].id,
              unitPriceShow: item.unitPriceShow,
              properties: [
                {
                  name: this.guige,
                  value: item.value
                }
              ]
            });
          }
          let picList = [];
          this.fileList.forEach(item => {
            if (item.response) {
              picList.push(item.response.id);
            } else {
              picList.push(item.id);
            }
          });
          values.pic = picList.join(",");

          // values.schoolBuyAll = this.schoolSelected.includes('true')
          values.categoryId = this.categroyStr; // 商品类型
          values.schoolBuyAll = true;
          if (this.schoolSelected.length > 0) {
            let temp = [];
            this.schoolSelected.forEach(item => {
              console.log("item",item);
              if (item !== "true" && item !== "" && item !== 'school') {//当供货方选项为中小学时不传值
                temp.push(item);
              }
            });
            values.blocTagBlocId = temp.join(",");
          } else {
            values.blocTagBlocId = "";
          }
          values.ageStage = values.ageStage.join(",");
          values.shoperType = values.shoperType.join(",");
          values.contentPc = this.content; // 商品详情
          console.log("下一步", values.spaces);
          // if (values.schoolBuySchoolId !== true) {
          //   values.schoolBuySchoolId = values.schoolBuySchoolId.join(',')
          //   values.schoolBuyAll = false
          // } else {
          //   values.schoolBuyAll = true
          // }
          values.propertyHtml = this.propertyHtml;
          if (this.id === "0") {
            addProduct(values)
              .then(res => {
                this.$message.success("录入成功");
                this.$router.push({ path: "/goodsList" });
              })
              .catch(() => {
                this.$message.error("操作失败");
              });
          } else {
            modifyProduct(this.id, values)
              .then(res => {
                this.$message.success("修改成功");
                this.$router.push({ path: "/goodsList" });
              })
              .catch(() => {
                this.$message.error("操作失败");
              });
          }
        }
      });
    },
    // 获取分类信息
    getCategory() {
      getCategory({ idParent: "0" }).then(res => {
        res.forEach(item => {
          item.value = item.id;
          item.label = item.name;
          if (item.childrenNum > 0) {
            getCategory({ idParent: item.id }).then(res1 => {
              res1.forEach(item1 => {
                item1.value = item1.id;
                item1.label = item1.name;
              });
              item.children = res1;
            });
          }
        });
        this.options = res;
      });
    },
    // 初始化信息
    get() {
      if (this.id !== "0") {
        getProductDetail(this.id).then(res => {
          this.product = res;
          this.categroyStr = res.categoryId;
          getCategoryDetail(res.categoryId).then(res => {
            if (res.parentCategory) {
              this.categoryIdList = [res.parentCategory.id, res.id];
            } else {
              this.categoryIdList = [res.id];
            }
          });
          this.product.shoperType = res.shoperType.split(",");
          this.product.ageStage = res.ageStage.split(",");

          //根据年龄层次勾选供货给选项
          if (res.ageStage && !res.ageStage.includes('KG')) {//中小学
            if (res.blocTagBlocId) {//供货方
              let list = res.blocTagBlocId.split(",");
              list.push('school');
              this.schoolSelected = list;
              this.product.blocTagBlocId = list;
            } else {
              this.product.blocTagBlocId = ['school'];
            }
          } else {//幼儿园
            if (res.blocTagBlocId) {//供货方
              let list = res.blocTagBlocId.split(",");
              this.schoolSelected = list;
              this.product.blocTagBlocId = list;
            } else {
              this.product.blocTagBlocId = [];
            }
          }



          if (res.pic) {
            res.pic.split(",").forEach((item, index) => {
              this.fileList.push({
                uid: (index + 1) * -1,
                id: item,
                name: "photo.jpg",
                status: "done",
                url: this.getPhoto(item)
              });
            });
          }
          this.spacesData = [];
          console.log("res", res.spaces);
          res.spaces.forEach((item, index) => {
            this.spacesData.push({
              id: index + 1,
              sku: item.sku,
              value: item.properties[0].value,
              picDefFileList: [
                {
                  uid: -1,
                  id: item.picDef,
                  name: "photo.jpg",
                  status: "done",
                  url: this.getPhoto(item.picDef)
                }
              ],
              unitPriceCost: item.unitPriceCost,
              unitPrice: item.unitPrice,
              unitPriceShow: item.unitPriceShow,
              num: item.num,
              transportPrice: item.transportPrice
            });
          });
          this.content = res.contentPc;
          // console.log('数据', this.spacesData)
          this.guige = res.spaces[0].properties[0].name;
        });
      }
    }
  },
  created() {
    this.get();
    this.getCategory();
  },
  mounted() {
    this.getMySchool();
  }
};
</script>

<style lang="less">
.specifications:before {
  content: "* " !important;
  color: #f5222d !important;
}

.plan {
  .ant-table-thead {

    th:nth-child(1):before,
    th:nth-child(2):before,
    th:nth-child(3):before,
    th:nth-child(4):before,
    th:nth-child(7):before {
      content: "* " !important;
      color: #f5222d !important;
    }
  }
}

.table-style-product {
  border: 1px solid #ccc;

  .title {
    line-height: 40px;
    padding: 0 16px;
    font-size: 16px;
    background: #f1f1f1;
    border-bottom: 1px solid #ccc;
  }

  .form-cont {
    >.ant-row {
      padding: 6px 0;
      border-bottom: 1px solid #ccc;

      /* .form-item-label{
        .ant-form-item-label{
          border-right: 1px solid #ccc;
          margin-right: 6px;
        }
      } */
      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-label label {
        font-weight: bold;
      }
    }

    .ant-form-item-label {
      width: 120px;
    }

    .core-label>.ant-form-item-label {
      width: 80px;
    }
  }

  .children-items .ant-form-item-label {
    width: 80px;
  }

  .pic-box>span {
    display: flex;
  }
}

.spaces-box .ant-form-item-control-wrapper {
  margin: 0 10px;
  width: 100%;
}

.footer-btn-bottom {
  text-align: center;
  margin: 20px 0;
}

// .ant-table-content {
//   .ant-upload.ant-upload-select-picture-card {
//     width: 80px;
//     height: 80px;
//   }
//   .ant-upload-list-picture-card {
//     .ant-upload-list-item {
//       width: 80px;
//       height: 80px;
//     }
//   }
// }
// .ant-upload-list-picture-card-container {
//   width: 80px;
//   height: 80px;
//   margin: 0;
// }

// .ant-upload-list-picture-card .ant-upload-list-item {
//   margin: 0;
//   padding: 4px;
// }
// .ant-upload.ant-upload-select-picture-card {
//   margin: 6px 0 0 0;
// }
</style>
