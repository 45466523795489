<template>
  <div>
    <editor
      :init="tinymceInit"
      v-model="currentContent"
      :key="tinymceFlag"
    ></editor>
    <a-modal
      class="upload-pic-modal"
      :visible="true"
      v-if="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <upload-pic-dragger
        :multipleNmber="20"
        ref="imageUpload"
        v-model="imageList"
        accept="image/*"
        :key="tinymceFlag"
      ></upload-pic-dragger>
      <div class="upload-pic-btn">
        <a-button type="primary" class="mr10" @click="insertImage"
          >确定</a-button
        >
        <a-button @click="handleCancel">取消</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import global from 'global'
import UploadPicDragger from '@/components/Uploader/UploadPicDragger'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/code'
import 'tinymce/icons/default/icons'
import instance from '@/utils/request'
export default {
  name: 'TinymceEditor',
  components: {
    Editor,
    UploadPicDragger
  },
  data () {
    return {
      tinymceFlag: 1,
      tinymceInit: {},
      currentContent: this.content,
      previewVisible: false,
      imageList: '',
      imageListArray: []
    }
  },
  model: {
    prop: 'content'
  },
  props: {
    content: {
      default: '',
      require: true
    }
  },
  watch: {
    currentContent (newVal, oldVal) {
      let url = global.file.url.substring(0, global.file.url.length - 3)
      // let url = global.file.url
      let res = /\/\//g
      if (!res.test(newVal)) {
        var newContent = newVal.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, capture) {
          // capture,返回每个匹配的字符串
          var newStr = `<img src="${url}${capture}" alt="" />`
          return newStr
        })
        this.$emit('input', newContent)
      } else {
        if (newVal !== oldVal) {
          this.$emit('input', newVal)
        }
      }
    }
  },
  methods: {
    // 插入图片至编辑器
    insertImage () {
      let ary = this.imageList.split(',')
      if (this.imageList.length === 0) {
        this.$message.warning('请上传图片')
        return
      }
      let url = global.file.url
      for (let index in ary) {
        tinymce.execCommand('mceInsertContent', false, `<img src=${url}/sys/fs/${ary[index]}>`)
      }

      this.previewVisible = false
      this.imageList = ''
      this.imageListArray = []
    },
    handleCancel () {
      this.previewVisible = false
    }
  },
  created () {
    this.tinymceInit = {
      skin_url: '/tinymce/skins/ui/oxide',
      language_url: `/tinymce/langs/zh_CN.js`,
      language: 'zh_CN',
      // height: document.body.offsetHeight - 300,
      // height: 300,
      browser_spellcheck: true, // 拼写检查
      branding: false, // 去水印
      // elementpath: false,  //禁用编辑器底部的状态栏
      statusbar: false, // 隐藏编辑器底部的状态栏
      paste_data_images: true, // 允许粘贴图像
      menubar: false, // 隐藏最上方menu
      autoresize_max_height: 500,
      powerpaste_allow_local_images: false,
      poaste_data_images: false,
      plugins: 'advlist table lists paste preview fullscreen code link autoresize',
      toolbar:
        'fontselect | fontsizeselect | forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | imageUpload quicklink h2 h3 blockquote table numlist bullist | link preview fullscreen code',
      /**
       * 为tinymce添加自定义插入图片按钮
       */
      setup: editor => {
        editor.ui.registry.addButton('imageUpload', {
          tooltip: '插入图片',
          icon: 'image',
          onAction: () => {
            this.previewVisible = true
          }
        })
      },
      // images_upload_handler: (blobInfo, success, failure) => {

      // },
      images_upload_handler: (
        blobInfo,
        success,
        failure
      ) => {
        let blob = blobInfo.blob()
        let fileData = new FormData()
        fileData.append('file', blob)

        let url = global.file.url + '/sys/fs'
        var xhr
        xhr = new XMLHttpRequest() // XMLHttpRequest 对象

        xhr.open('post', url, true) // post方式，url为服务器请求地址，true 该参数规定请求是否异步处理。

        xhr.onload = (res) => {
          var data = JSON.parse(res.target.responseText)
          console.debug(res)
          success(url + '/' + data.id)
        } // 请求完成

        xhr.send(fileData) // 开始上传，发送form数据

        // instance.post(url, fileData).then((res) => {
        //   // debugger;
        //   console.log(res)
        //   success(this.getUrl(res.id))
        // })
      }
    }
  },
  activated () {
    this.tinymceFlag++
  },
  mounted () {}
}

</script>

<style lang="less">
.upload-pic-modal {
  .ant-modal-body {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .upload-pic-btn {
    text-align: center;
    margin-top: 10px;
  }
}
</style>
