import request from '@/utils/request'
import global from 'global'

const getFileName = (id, params) => {
  return request({
    url: `/sys/fs/${id}`,
    params,
    method: 'get'
  })
}

const postFile = (data) => {
  return request({
    url: `${global.file.url}/sys/fs`,
    data,
    method: 'post'
  })
}

export {
  getFileName,
  postFile
}
